import Lightbox from 'stimulus-lightbox'

export default class extends Lightbox {
  connect() {
    super.connect()
    this.lightGallery
  }

}

